<template>
  <div class="flex justify-center items-center w-full">
    <div class="bg-lightgray h-2 rounded-lg relative flex flex-grow items-center ml-3 mr-4" id="bar">
      <div id="blue-bar" class="bg-blue h-2 rounded-lg relative" :style="`width: ${position}%`"></div>
      <div id="range-knob" :style="{
             'border-color': knob_toggled? 'rgb(244, 244, 244)' : 'white',
             'left': `${position}%`
           }"
           class="absolute range-knob bg-black cursor-pointer"
           style="box-shadow: 0 0 15px 7px rgb(0 0 0 / 0.1)"></div>
    </div>
  </div>
</template>

<style>
.bg-lightgray {
  background: rgb(248 248 248);
}

.range-knob {
  width: 20px;
  height: 20px;
  border: 5px solid white;
  border-radius: 50%;
  transform: translate(-50%, 0);
}
</style>

<script>
export default {
  name: "DistanceRange",
  data() {
    const positions = this.get_positions(this.$props.values);
    const index = this.$props.values.indexOf(this.$props.initialValue);
    return {
      knob_toggled: false,
      position: positions[index],
      positions
    }
  },
  props: ['values', 'initialValue'],
  emits: ['valuechanged'],
  mounted() {
    const knob = document.getElementById('range-knob');

    knob.addEventListener('mousedown', function () {
      this.setKnobToggled(true);
    }.bind(this));
    document.addEventListener('mouseup', function () {
      this.setKnobToggled(false);
    }.bind(this));

    knob.addEventListener('touchstart', function () {
      this.setKnobToggled(true);
    }.bind(this));

    document.addEventListener('touchmove', function (event) {
      this.onMouseMove(event, true);
    }.bind(this), {passive: false});


    document.addEventListener('mousemove', function (event) {
      this.onMouseMove(event, false);
    }.bind(this));

    document.addEventListener('touchend', function () {
      this.setKnobToggled(false);
    }.bind(this));
  },
  methods: {
    onMouseMove(event, touch) {
      if (!this.knob_toggled) {
        return;
      }
      event.preventDefault();
      if (touch && !event.touches) {
        return;
      }
      const clientX = touch ? event.touches[0].clientX : event.clientX;
      const barRect = document.getElementById('bar').getBoundingClientRect();
      let position;
      if(clientX < barRect.left) {
        position = this.positions[0];
      }
      else if(clientX > barRect.right) {
        position = this.positions[this.positions.length - 1];
      }
      else {
        position = Math.floor((clientX - barRect.left) / (barRect.right - barRect.left) * 100);
        let minDistance = 10000;
        for(let i = 0; i < this.positions.length; ++i) {
          let distance = Math.abs(position - this.positions[i]);
          if(distance < minDistance) {
            minDistance = distance;
          }
          else {
            position = this.positions[i - 1];
            this.position_select(position);
            return;
          }
        }
        position = this.positions[this.positions.length - 1];
      }
      this.position_select(position);
    },
    setKnobToggled(value) {
      this.knob_toggled = value;
      document.body.style.userSelect = value ? 'none' : 'auto';
    },
    position_select(position, force = false) {
      if (this.position === position) {
        return;
      }
      if (force || this.knob_toggled) {
        this.position = position;
        this.setKnobToggled(true);
        const index = this.positions.indexOf(position);
        this.$emit('valuechanged', this.$props.values[index]);
      }
    },
    get_positions(values) {
      let positions = [];
      for(let i = 0; i < values.length; ++i) {
        positions.push(Math.floor(i / (values.length - 1) * 100));
      }
      positions[0] = 5;
      return positions;
    }
  }
}
</script>