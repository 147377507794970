<template>
  <FixedRatioContainer
      :container-class="'bg-lightgray mx-auto'">
    <div class="grid-box p-4 md:p-8 w-full h-full flex flex-col">
      <div :class="{'hidden': !$store.getters['location/determined']}"
           class="flex flex-col h-full w-full">
        <h1 class="text-left">{{ $t('compareCharacters.title') }} <span class="font-medium text-sm">in {{ $store.getters['account/location_string'] }}</span></h1>
        <div class="mt-5 flex justify-between">
          <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-0">
            <div :class="get_button_class($store.getters['compare_character/congruence_filter_state'])"
                 class="select-none rounded-xl flex justify-between items-center py-2 w-full md:w-auto px-5 cursor-pointer bg-gray"
                 @click="filter_click('congruence')">
              {{ $t('compareCharacters.congruence') }}
              <div v-if="$store.getters['compare_character/congruence_filter_state'] === 2" class="ml-2"><i
                  class="fas fa-angle-up"></i></div>
              <div v-else class="ml-2"><i class="fas fa-angle-down"></i></div>
            </div>
            <div :class="get_button_class($store.getters['compare_character/distance_filter_state'])"
                 class="select-none rounded-xl flex justify-between items-center py-2 px-5 ml-0 w-full md:w-auto md:ml-5 cursor-pointer bg-gray"
                 @click="filter_click('distance')">
              {{ $t('compareCharacters.distance') }}
              <div v-if="$store.getters['compare_character/distance_filter_state'] === 2" class="ml-2"><i
                  class="fas fa-angle-up"></i></div>
              <div v-else class="ml-2"><i class="fas fa-angle-down"></i></div>
            </div>
          </div>
          <div
              class="my-auto self-start select-none rounded-xl border flex justify-center items-center px-5 py-2 ml-2 cursor-pointer bg-blue text-white"
              @click="on_add_filter_click">
            {{ $t('compareCharacters.addFilter') }}
            <i class="fas fa-filter ml-2"></i>
          </div>
        </div>
        <div class="my-4 md:my-8">
          <DistanceRange :initial-value="$store.getters['compare_character/max_distance']"
                         :values="get_distance_steps()"
                         @valuechanged="set_max_distance"></DistanceRange>
        </div>
        <hr/>
        <div v-if="get_selected_activities_as_list().length > 0" class="my-2">
          <div v-for="activity in get_selected_activities_as_list()" :key="activity.id"
               class="inline-flex rounded-xl justify-center items-center px-2 py-1 selected mr-2 mt-2 cursor-pointer"
               style="border-width: 2px"
               @click="select_activity(activity); assign_filtered_users()">
            {{ activity.name }}
            <div class="ml-2 cursor-pointer"><i class="fas fa-close"></i></div>
          </div>
        </div>
        <div class="grid grid-cols-12 px-6 mt-4 mb-1">
          <div class="col-span-7 text-left">{{ $t('compareCharacters.username') }}</div>
          <div class="col-span-3">{{ $t('compareCharacters.match') }}</div>
          <div class="col-span-2">{{ $t('compareCharacters.distance') }}</div>
        </div>
        <div class="overflow-scroll no-scrollbar">
          <div v-for="(user, index) in filtered_users" :key="index"
               class="grid grid-cols-12 px-2 md:px-6 py-4 rounded-2xl bg-lightgray my-3 flex items-center font-bold cursor-pointer"
               @click="go_to_user(index)">
            <div class="col-span-2">
              <img v-if="user.profile_picture"
                   loading="lazy"
                   :src="$store.getters['common/baseURL'] + user.profile_picture"
                   class="object-cover"
                   style="height: 40px; width: 40px; border-radius: 50%"/>
              <div v-else class="bg-gray-300 flex items-center justify-center"
                   style="width: 40px; height: 40px; border-radius: 50%;">
                <i class="fas fa-user text-xl text-white"></i>
              </div>
            </div>
            <div class="col-span-5 pl-1">{{ user.username }}</div>
            <div class="col-span-3">{{ $store.getters['account/has_premium']? `${user.character_congruence}%` : '?' }}</div>
            <div class="col-span-2">{{ user.distance !== null ? $store.getters['character/format_distance'](user.distance) :
                '?' }}</div>
          </div>
        </div>
      </div>

    </div>

    <GetPremium v-if="$store.getters['common/showPremiumModal']"></GetPremium>

    <div class="fixed bg-white px-10 py-5 shadow-lg rounded-xl"
         :class="{'hidden': hideModal}"
         style="top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 101; width: 95%; max-width: 500px;
       max-height: 500px">
      <div class="flex justify-between items-center mb-4">
        <h1>{{ $t('compareCharacters.addFilterModal.title') }}</h1>
        <div class="cursor-pointer" @click="on_add_filter_click(true)">
          <i class="fas fa-times text-3xl"></i>
        </div>
      </div>
      <hr/>
      <p class="mt-2">{{ $t('compareCharacters.addFilterModal.text') }}</p>

      <div class="w-full relative">
        <input v-model="activity_search_term"
               id="activity_search"
               :placeholder="$t('compareCharacters.addFilterModal.searchPlaceholder')"
               class="p-3 bg-gray rounded-xl my-3 w-full" type="text"/>
        <div class="absolute right-4 top-1/2"
             style="transform: translate(0, -45%); color: rgb(150, 159, 173)"><i class="fas fa-search"></i></div>
      </div>
      <div class="flex flex-col overflow-y-scroll overflow-x-hidden" style="height: 200px">
        <div v-for="activity in filtered_activities" :key="activity.name"
             :class="{'selected':
           is_selected(activity)}"
             class="grid grid-cols-2 w-full rounded-xl bg-lightgray h-3/4 mb-3 cursor-pointer" style="padding: 6px 6px"
             @click="select_activity(activity)">
          <img :src="$store.getters['common/baseURL'] + activity.image"
               class="rounded-xl object-cover w-full"
               loading="lazy"
               style="max-height: 138px"/>
          <div class="flex flex-col justify-center break-words px-2">
            <div class="mb-4 text-center font-bold text-xl md:text-2xl">{{ activity.name }}</div>
            <div class="text-center font-bold">{{ activity.score }}%</div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center mt-3">
        <button class="w-1/3 py-4 bg-white text-blue rounded-xl border-blue mr-8"
                @click="clear_selected_activities">
          {{ $t('compareCharacters.addFilterModal.clear') }}
        </button>
        <button class="w-1/3 py-4 bg-blue text-white rounded-xl"
                @click="on_add_filter_click(true)">
          {{ $t('compareCharacters.addFilterModal.ok') }}
        </button>
      </div>
    </div>

    <div id="knob-tooltip" class="tooltip bg-white rounded hidden p-2" style="color: rgb(113 134 187);">
      {{ $store.getters['compare_character/max_distance'] }} km
      <div class="arrow" data-popper-arrow></div>
    </div>
  </FixedRatioContainer>
</template>

<style>
.selected {
  border: 1px solid rgb(113 134 187);
  color: rgb(113 134 187);
}

.border-1-transparent {
  border: 1px solid transparent;
}
</style>

<script>
import DistanceRange from "@/components/DistanceRange";
import {createPopper} from "@popperjs/core";
import FixedRatioContainer from "@/components/FixedRatioContainer";
import GetPremium from "@/components/GetPremium";

export default {
  components: {
    GetPremium,
    FixedRatioContainer,
    DistanceRange
  },
  watch: {
    activity_search_term() {
      this.filter_activities();
    }
  },
  data() {
    let filtered_activities = [];
    for(const activity of this.$store.getters['activity/activities']) {
      if(activity.score !== null) {
        filtered_activities.push(activity);
      }
    }
    return {
      filtered_users: [],
      congruence_correction_factor: 1,
      filtered_activities,
      activity_search_term: null,
      popper: null,
      hideModal: true
    }
  },
  methods: {
    filter_activities() {
      this.filtered_activities = [];
      for (const activity of this.$store.getters['activity/activities']) {
        if (activity.name.toLowerCase().indexOf(this.activity_search_term.toLowerCase()) !== -1) {
          this.filtered_activities.push(activity);
        }
      }
    },
    clear_selected_activities() {
      this.activity_search_term = '';
      this.$store.commit('compare_character/set_selected_activities', {selected_activities: {}});
      this.assign_filtered_users();
    },
    select_activity(activity) {
      let selected_activities = this.$store.getters['compare_character/selected_activities'];
      if (activity.id in selected_activities) {
        delete selected_activities[activity.id];
      } else {
        selected_activities[activity.id] = 1;
      }

      this.$store.commit('compare_character/set_selected_activities', {selected_activities});
    },
    get_selected_activities_as_list() {
      const list = [];
      for (const id in this.$store.getters['compare_character/selected_activities']) {
        list.push(this.$store.getters['activity/activity_map'][id]);
      }
      return list;
    },
    filter_by_activity() {
      for (let i = this.filtered_users.length - 1; i >= 0; --i) {
        for (const activity of this.get_selected_activities_as_list()) {
          if (this.filtered_users[i].liked_activities.indexOf(activity.id) === -1) {
            this.filtered_users.splice(i, 1);
            break;
          }
        }
      }
    },
    is_selected(activity) {
      return activity.id in this.$store.getters['compare_character/selected_activities'];
    },
    set_congruence_correction_factor() {
      if(!this.$store.getters['account/has_premium']) {
        return 1;
      }
      let min_distance = 0, max_distance = 0;
      let min_congruence = 0, max_congruence = 0;
      for (const user of this.filtered_users) {
        if (user.distance > max_distance) {
          max_distance = user.distance;
        }
        if (user.distance < min_distance) {
          min_distance = user.distance;
        }
        if (user.character_congruence > max_congruence) {
          max_congruence = user.character_congruence;
        }
        if (user.character_congruence < min_congruence) {
          min_congruence = user.character_congruence;
        }
      }
      if(!(max_distance - min_distance > 0)) {
        return 1;
      }
      this.congruence_correction_factor = (max_distance - min_distance) / (max_congruence - min_congruence);
    },
    assign_filtered_users() {
      this.filtered_users = [];
      for (const user of this.$store.getters['users/all']) {
        if (user.distance / 1000 < this.$store.getters['compare_character/max_distance']) {
          this.filtered_users.push(user);
        }
      }
      this.filter_by_activity();
      this.set_congruence_correction_factor();
      this.filtered_users.sort(this.get_sort_key());
    },
    filter_click(type) {
      let congruence_filter_state = this.$store.getters['compare_character/congruence_filter_state'];
      let distance_filter_state = this.$store.getters['compare_character/distance_filter_state'];

      if (type === 'congruence') {
        if(congruence_filter_state === 0 && !this.$store.getters['account/has_premium']) {
          this.$store.commit('common/setShowPremiumModal', { status: true });
          return;
        }
        congruence_filter_state > 1 ? congruence_filter_state = 0 : congruence_filter_state++;
        if (congruence_filter_state === 0 && distance_filter_state === 0) {
          congruence_filter_state = 1;
        }
      } else {
        distance_filter_state > 1 ? distance_filter_state = 0 : distance_filter_state++;
        if (distance_filter_state === 0 && congruence_filter_state === 0) {
          distance_filter_state = 1;
        }
      }

      this.$store.commit('compare_character/set_congruence_filter_state', {congruence_filter_state});
      this.$store.commit('compare_character/set_distance_filter_state', {distance_filter_state});

      this.filtered_users.sort(this.get_sort_key());
    },
    get_button_class(state) {
      if (state > 0) {
        return 'selected';
      }
      return 'border-1-transparent';
    },
    congruence_score(a, b) {
      let value;
      const congruence_filter_state = this.$store.getters['compare_character/congruence_filter_state'];
      if (congruence_filter_state === 2) {
        value = a.character_congruence - b.character_congruence;
      } else if (congruence_filter_state === 1) {
        value = b.character_congruence - a.character_congruence;
      } else {
        value = 0;
      }
      return this.congruence_correction_factor * value;
    },
    distance_score(a, b) {
      if (this.$store.getters['compare_character/distance_filter_state'] === 1) {
        return a.distance - b.distance;
      } else if (this.$store.getters['compare_character/distance_filter_state'] === 2) {
        return b.distance - a.distance;
      }
      return 0;
    },
    get_sort_key() {
      return (a, b) => {
        return this.congruence_score(a, b) - this.distance_score(a, b);
      }
    },
    go_to_user(index) {
      const user = this.filtered_users[index];
      this.$store.commit('users/set_selected_user', {user});
      this.$router.push('/user-detail');
    },
    on_add_filter_click(closed = false) {
      document.getElementById('blackout').classList.toggle('hidden');
      this.hideModal = !this.hideModal;

      if (closed) {
        this.assign_filtered_users();
      }
    },
    set_max_distance(value) {
      this.$store.commit('compare_character/set_max_distance', {max_distance: value});
      this.assign_filtered_users();
      this.popper.update();
    },
    create_popper() {
      if (!this.$store.getters['location/determined']) {
        return;
      }
      const tooltip = document.getElementById('knob-tooltip');
      const popper = createPopper(
          document.getElementById('range-knob'),
          tooltip,
          {
            placement: 'bottom',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10]
                }
              }
            ]
          }
      );

      tooltip.classList.toggle('hidden');
      popper.update();
      this.popper = popper;
    },
    get_distance_steps() {
      let steps = [];
      const step = 100;
      for(let distance = step; distance <= this.$store.getters['compare_character/distance_upper_bound'] / 1000;
          distance += step) {
        steps.push(distance);
      }
      return steps;
    }
  },
  mounted() {
    this.assign_filtered_users();
    this.create_popper();
  }
}
</script>
